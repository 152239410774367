<template>
    <div>
        <div v-if="authUserPermission['task-menus-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('task_menus') }}</h3>
                            </div>
                            <div class="col-5">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_task_menus')" v-on:keyup="filter"/>                            
                                </div>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="update_sequence()" v-if="authUserPermission['task-menus-update-sequence']" :disabled="!btnUpdateSequence.afterChange">
                                    <span v-if="btnUpdateSequence.onUpdate"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>{{ tt('update_sequence') }}</span>
                                </base-button>
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['task-menus-create']">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row" v-if="!onLoad">
                            <div class="col-12">
                                <div style="overflow-y: auto;overflow-x: auto;height: 500px;">
                                    <table class="table">
                                        <thead class="thead-light">
                                        <tr>
                                            <th scope="col">{{tt('sequence')}}</th>
                                            <th scope="col">{{tt('task')}}</th>
                                            <th scope="col">{{tt('menu')}}</th>
                                            <th scope="col">{{tt('icon')}}</th>
                                            <th scope="col">{{tt('attributes')}}</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <draggable v-model="table.data" tag="tbody" @change="change_data()">
                                            <tr v-for="row in table.data" :key="row.menu">
                                                <td>{{ row.sequence }}</td>
                                                <td>{{ row.task_code }}</td>
                                                <td>{{ row.menu }}</td>
                                                <td>{{ row.icon }}</td>
                                                <td>{{ row.attributes }}</td>
                                                <td class="text-right">
                                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['task-menus-update'] || authUserPermission['task-menus-delete']">
                                                        <span class="btn btn-sm btn-icon-only text-light">
                                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                                        </span>
                                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['task-menus-update']">Edit</el-dropdown-item>
                                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['task-menus-delete']">Delete</el-dropdown-item>                                        
                                                        </el-dropdown-menu>
                                                    </el-dropdown>
                                                </td>
                                            </tr>
                                        </draggable>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('task')" :prop="tt('task')" min-width="140px" sortable>
                                <template v-slot="{row}">
                                    {{row.task_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('menu')" :prop="tt('menu')" min-width="160px" sortable>
                                <template v-slot="{row}">
                                    {{row.menu}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}">Edit</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}">Delete</el-dropdown-item>                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table> -->
                        <skeleton-loading v-else/>
                    </div> 
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('task') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('task')" rules="required">
                            <el-select class="select-danger" v-model="taskMenus.task_code" :placeholder="tt('choose_task')">
                                <el-option class="select-danger" :value="t['tasks_code']" :label="t['task']" :key="t['task']" v-for="t in  tasks"></el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('menu') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('menu')" :placeholder="tt('menu')" v-model="taskMenus.menu" rules="required|max:80"></base-input>

                        <label class="form-control-label">{{ tt('symbol') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('symbol')" :placeholder="tt('symbol')" v-model="taskMenus.icon" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('attributes') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('attributes')" :placeholder="tt('attributes')" v-model="taskMenus.attributes" rules="required" v-if="form.add"></base-input>
                        <base-input :name="tt('attributes')" :placeholder="tt('attributes')" v-model="taskMenus.attributes_task_menus" rules="required" v-else></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import taskMenus from '@/services/setting/taskMenus.service';
    import tasks from '@/services/setting/tasks.service';

    import draggable from "vuedraggable";
    let id = 1;

    export default {        
        data() {
            return {   
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false,
                },           
                btnUpdateSequence: {
                    beforeChange: true, //disabled
                    afterChange: false,
                    onUpdate: false,
                },
                form: {
                    add: true,
                    title: this.tt('add_task_menus'),
                    show: false
                }, 
                table: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',                    
                    total: 0,
                    data: [],
                    data_old: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },     
                errorMessage: null,  
                taskMenus: {},
                tasks: {},

                list: [
                    { id: 1, name: "Abby", sport: "basket" },
                    { id: 2, name: "Brooke", sport: "foot" },
                    { id: 3, name: "Courtenay", sport: "volley" },
                    { id: 4, name: "David", sport: "rugby" }
                ],
                dragging: false
            }
        },
        name: "table-example",
        display: "Table",
        order: 8,
        components: {
            draggable
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
            // draggingInfo() {
            //     return this.dragging ? "under drag" : "";
            // }
        },
        mounted() {
            this.get()
            this.getTasks()
        },
        methods: {
            change_data() {
                this.btnUpdateSequence.beforeChange = false;
                this.btnUpdateSequence.afterChange = true;
                this.btnUpdateSequence.onUpdate = false;
            },
            update_sequence() {
                let context = this;
                let seq_id = [];
                let dataUpdate = {
                    'id': seq_id
                };
                context.btnUpdateSequence.beforeChange = false;
                context.btnUpdateSequence.afterChange = false;
                context.btnUpdateSequence.onUpdate = true;
                for(var i=0; i<context.table.data.length; i++) {
                    seq_id.push(context.table.data[i].id);
                }
                Api(context, taskMenus.update_sequence(dataUpdate)).onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.onLoad = false;
                    context.btnUpdateSequence.beforeChange = true;
                    context.btnUpdateSequence.afterChange = false;
                    context.btnUpdateSequence.onUpdate = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                }).call()
            },
            get() { 
                let context = this;               
                Api(context, taskMenus.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                    context.table.data_old = response.data.data.data.data;  
                    context.table.page  = response.data.data.data;  
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            getTasks() {
                let context = this;               
                Api(context, tasks.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.tasks = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.tasks = [];
                    }
                })
                .call()
            },
            filter() {
                if(this.table.search != this.$route.query.search){
                    this.onLoad = true;
                    this.table.page.current_page = 1;

                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.table.search == ''){
                            this.$router.push({path:'/setting/task-menus', query:null})
                        }else{
                            this.$router.push({path:'/setting/task-menus', query:{search:this.table.search}})
                        }
                        this.get()
                    }, 100);
                }
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_task_menus');
                this.form.show = true;
                this.taskMenus = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, taskMenus.show(id)).onSuccess(function(response) {
                    context.taskMenus = response.data.data;
                    context.taskMenus.attributes_task_menus = response.data.data.attributes;
                    context.form.add = false;
                    context.form.title = context.tt('edit_task_menus');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                context.taskMenus.sequence = context.table.total+1;
                    // alert(typeof context.taskMenus.sequence);
                if (context.form.add) {
                    context.taskMenus.parent_id = 0;
                    api = Api(context, taskMenus.create(context.taskMenus));
                } else {{
                    api = Api(context, taskMenus.update(context.taskMenus.id, context.taskMenus));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, taskMenus.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
